AgrimarketServices.service('contentService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null);
                })
                .error(function (data, status) {
                    callback(data, status);
                });
        };

        this.GetContents = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Content/GetContent',
            };
            SendRequest(config, callback);
        };

        this.GetProfiles= function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Content/GetProfiles',
            };
            SendRequest(config, callback);
        };

        this.LoadAllGroupContact = function(callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Content/LoadAllGroupContact',
            };
            SendRequest(config, callback);
        };

        this.GetPageContent = function (idPage,callback) {
        	var config = {
        		method: 'GET',
        		url: configurationFactory.Url['Api'] + 'Content/GetPageContent',
        		params: { idPage: idPage }
        	};
        	SendRequest(config, callback);
        };

        this.SavePosition = function (contents,callback) {
        	var config = {
        		method: 'POST',
        		data: contents,
        		url: configurationFactory.Url['Api'] + 'Content/SaveContent',
        	};
        	SendRequest(config, callback);
        };
}]);