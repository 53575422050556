AgrimarketServices.service('userService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {
        var sendRequest = function (configfile, callback) {
            $http(configfile)
                .success(function (data) {
                    callback(data, null, null, null);
                })
                .error(function (data, status, headers, config) {
                    callback(data, status, headers, config);
                });
        };

        this.GetUserByIdRole = function (idrole, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'User/GetUserByIdRole',
                params: { idrole: idrole }
            };
            sendRequest(config, callback);
        };

        this.GetUserByIdRolePaginate = function (idrole, itemPerPage, pageNo, sortKey, reverse, filter, isActif, idCompagny, idGroupContact, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'User/GetUserByIdRolePaginate',
                params: {
                    idrole: idrole,
                    itemPerPage: itemPerPage,
                    pageNo: pageNo,
                    sortKey: sortKey,
                    reverse: reverse,
                    filter: filter,
                    isActif: isActif,
                    idCompagny: idCompagny,
                    idGroupContact: idGroupContact
                }
            };
            sendRequest(config, callback);
        };

        this.GetUserId = function (idrole, sortKey, reverse, filter, isActif, idCompagny, idGroupContact, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'User/GetUserId',
                params: {
                    idrole: idrole,
                    sortKey: sortKey,
                    reverse: reverse,
                    filter: filter,
                    isActif: isActif,
                    idCompagny: idCompagny,
                    idGroupContact: idGroupContact
                }
            };
            sendRequest(config, callback);
        };

        this.SaveClientSelectedFromTC = function (idUser, refreshMenus, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'User/SaveClientSelectedFromTC',
                params: { idUser: idUser, refreshMenus: refreshMenus }
            };
            sendRequest(config, callback);
        };

        this.DisconnectClientSelectedFromTC = function (callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'User/DisconnectClientSelectedFromTC'
            };
            sendRequest(config, callback);
        };

        this.DoUsersAction = function (userAction, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'User/DoUsersAction',
                data: userAction
            };
            sendRequest(config, callback);
        };

        this.GetAllUser = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'User/GetAllUserGroup'
            };
            sendRequest(config, callback);
        };

        this.SaveUser = function (user, callback) {
            var config = {
                method: "POST",
                url: configurationFactory.Url['Api'] + 'User/UserVm',
                data: user
            };
            sendRequest(config, callback);
        };

        this.DeleteLogicallyUser = function (idUser, callback) {
            var config = {
                method: "PUT",
                url: configurationFactory.Url['Api'] + 'User/DeleteLogicallyUser',
                params: { idUser: idUser }
            };
            sendRequest(config, callback);
        };

        this.CheckExistOrderUserInExecution = function (idUser, callback) {
            var config = {
                method: "POST",
                url: configurationFactory.Url['Api'] + 'User/CheckExistOrderUserInExecution',
                params: { idUser: idUser }
            };
            sendRequest(config, callback);
        };

        this.GetRecommendationsUser = function (idCrop, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'User/GetRecommendationsUser',
                params: { idCrop: idCrop }
            };
            sendRequest(config, callback);
        };

        this.GetAccountStateUser = function (callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'User/GetAccountStateUser',
            };
            sendRequest(config, callback);
        };

        this.UnsubscribeNewsMarketInfoByIdExt = function (param, hash, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url.Api + 'User/UnsubscribeNewsMarketInfoByIdExt',
                params: { idExt: param, hash: hash }
            };
            sendRequest(config, callback);
        };

        this.SaveValidatedCGU = function (idUser, receiveNewsMarketInfo, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url.Api + 'User/SaveValidatedCGU',
                params: { idUser: idUser, receiveNewsMarketInfo: receiveNewsMarketInfo }
            };
            sendRequest(config, callback);
        };

        this.SaveLinkTc = function (idUser, idTcUser, callback) {
            var config = {
                method: "POST",
                url: configurationFactory.Url['Api'] + 'User/SaveLinkTc',
                params: { idUser: idUser, idTcUser: idTcUser }
            };
            sendRequest(config, callback);
        };

        this.SaveLanguageSelected = function (idLanguageSelected, callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'User/SaveLanguageSelected',
                params: { idLanguageSelected: idLanguageSelected }
            };
            sendRequest(config, callback);
        };

        this.DeleteLinkTc = function (idUser, idTcUser, callback) {
            var config = {
                method: "DELETE",
                url: configurationFactory.Url['Api'] + 'User/DeleteLinkTc',
                params: { idUser: idUser, idTcUser: idTcUser }
            };
            sendRequest(config, callback);
        };

        this.GetDocumentsUser = function (idCrop, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'User/GetDocumentsUser',
                params: { idCrop: idCrop }
            };
            sendRequest(config, callback);
        };

        this.UpdateUserInfosByTc = function (user, callback) {
            var config = {
                method: "PUT",
                url: configurationFactory.Url['Api'] + 'User/UpdateUserInfosByTc',
                data: user
            };
            sendRequest(config, callback);
        };

        this.VerifUserLight = function (user) {
            var errorsPopup = "";

            if (!user.LastName)
                errorsPopup += "<li>" + $rootScope.editableObjectsFactory.EditableObjects.User_Personal_Info_ErrorMessage_LastName.Content +"</li>";

            if (!user.Mail)
                errorsPopup += "<li>" + $rootScope.editableObjectsFactory.EditableObjects.User_Personal_Info_ErrorMessage_Mail.Content + "</li>";
            else {
                var regexMail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
                if (!regexMail.test(user.Mail))
                    errorsPopup += "<li>" + $rootScope.editableObjectsFactory.EditableObjects.User_Personal_Info_ErrorMessage_Mail.Content + "</li>";
            }

            var regexTel = /(\+33|[0-9])?[-.() ]?[0-9]$/;
            if (user.PhoneNumber) {
                if (!regexTel.test(user.PhoneNumber))
                    errorsPopup += "<li>" + $rootScope.editableObjectsFactory.EditableObjects.User_Personal_Info_ErrorMessage_PhoneNumber.Content + "</li>";
            }
            if (user.MobileNumber) {
                if (!regexTel.test(user.MobileNumber))
                    errorsPopup += "<li>" + $rootScope.editableObjectsFactory.EditableObjects.User_Personal_Info_ErrorMessage_MobileNumber.Content + "</li>";
            }

            return errorsPopup;
        };

        this.GetUsersOfTc = function (callback) {
            var config = {
                method: "GET",
                url: configurationFactory.Url['Api'] + 'User/GetUsersOfTc',
                data: user
            };
            sendRequest(config, callback);
        };

        this.CheckEffortTc = function (effortTcValue, initialPrice, typeEffortTc, coeffValue, isGlobalPriceOrSecondComponent, decimalForComponents,callback) {
            var config = {
                method: "POST",
                url: configurationFactory.Url['Api'] + 'User/CheckEffortTc',
                params: { effortTcValue: effortTcValue, initialPrice: initialPrice, typeEffortTc: typeEffortTc, coeffValue: coeffValue, isGlobalPriceOrSecondComponent: isGlobalPriceOrSecondComponent, decimalForComponents: decimalForComponents}
            };
            sendRequest(config, callback);
        };

        this.DisconnectExploitation = function (callback) {
            var config = {
                method: 'POST',
                url: configurationFactory.Url['Api'] + 'User/DisconnectExploitation'
            };
            sendRequest(config, callback);
        };
    }]);