AgrimarketControllers.controller('userSubscribeContractSurfaceCommitmentController', ['$scope', '$rootScope', '$location', 'currentUserFactory', 'enumFactory', 'configurationFactory', 'parametersFactory', 'offerService', 'parametersService', 'contractsService', '$filter', 'signatureService', 'conditionsService',
    function ($scope, $rootScope, $location, currentUserFactory, enumFactory, configurationFactory, parametersFactory, offerService, parametersService, contractsService, $filter, signatureService, conditionsService) {

        var init = function () {
            $scope.selectedVariety = {};
            $scope.checkValidCondition = false;
            $scope.showUrlSellConditionAttachment = false;
            $scope.showUrlUtilConditionAttachment = false;
            $scope.withConditions = true;
            $scope.enumFactory = enumFactory;
            $scope.typeOffer = enumFactory.TypeOffer.EngagementSurface;
            $scope.selectedConnection = {
                Connection: {}
            };
            $scope.signatureYouSign = {};
            $scope.showListChooseContact = false;
            $scope.moduleYousignIsActive = $rootScope.ModuleSignatureElectroniqueYouSign.Active;
            $scope.listConnection = [];
            $scope.showConfirmButton = true;
            $scope.user = currentUserFactory.User;
            $scope.mobileNumberToSendSms = $rootScope.selectedClientFromTc ? $rootScope.selectedClientFromTc.MobileNumber : currentUserFactory.Connection ? currentUserFactory.Connection.MobileNumber : $scope.user.MobileNumber;

            $scope.termsAndConditions = {
                isChecked: false
            };
            $scope.security = {
                Code: ""
            };
            $scope.counter = {
                start: false,
                pause: false
            };

            //Prise en compte des modifications des parametres par l'Admin
            parametersService.GetParameters(function (dataParam, statusParam) {
                if (statusParam !== undefined && statusParam !== null) {
                    if (statusParam === 400) {
                        $scope.loginErrorMessage = dataParam[0].errors[0].Message;
                    }
                } else {
                    parametersFactory.Parameters = dataParam;

                    if (!parametersFactory.Parameters.FileTermCondition.Value)
                        $scope.showUrlSellConditionAttachment = false;
                    else {
                        $scope.urlSellConditionAttachment = configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + parametersFactory.Parameters.FileTermCondition.Value;
                        $scope.showUrlSellConditionAttachment = true;
                    }

                    if (!parametersFactory.Parameters.FileTermeUse.Value)
                        $scope.showUrlUtilConditionAttachment = false;
                    else {
                        $scope.urlUtilConditionAttachment = configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + parametersFactory.Parameters.FileTermeUse.Value;
                        $scope.showUrlUtilConditionAttachment = true;
                    }
                }
            });

            if ($rootScope.idOfferSelected) {
                // Récupération de l'offre à souscrire
                offerService.GetOfferByIdAndIdExpiry($rootScope.idOfferSelected, $rootScope.idExpirySelected, "", function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = data[0].errors[0].Message;
                    } else {
                        $scope.resumeOffer = data;
                        $scope.SurfaceMin = data.TonnageMin;
                        $scope.SurfaceMax = data.TonnageMax;
                        $scope.signingEnabled = ($scope.moduleYousignIsActive || $rootScope.ModuleElectronicSignature.Active) && $rootScope.activateSignature && $scope.resumeOffer.ContractSigningEnabled;

                        // Récupération des conditions particulères
                        GetCondParts();

                        if (!$scope.step)
                            goToStep("contractualisation");

                    }
                });
            } else
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_IdentifierOfOfferIsMissing.Content;
        };

        var GetCondParts = function () {
            conditionsService.GetCondParts($scope.resumeOffer.IdOffer, $scope.resumeOffer.IdTree, null, false, null, '', function (res) {
                $scope.errorMessage = res.errorMessage;
                $scope.conditionParticulars = res.conditionParticulars;
                $scope.withConditions = res.conditionParticulars.length > 0;
            });
        };

        var SaveNewContrat = function (newContractVm) {
            contractsService.SaveNewContract(newContractVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isToLate = true;
                        $scope.isConfirmed = false;
                        $scope.counter.pause = true;
                    }
                } else {
                    $scope.idContractSurfaceCommitment= data.idContract;
                    $scope.hashContract = data.hashContract;   
                    $scope.counter = {};
                    $scope.counter.start = false;
                    goToStep("confirmation");
                }
            });
        };

        $scope.Validate = function () {
            $scope.errorMessage = "";
            $scope.errorSurfaceMessage = "";
            $scope.errorPriceMessage = "";
            $rootScope.lastOfferSelected = $scope.resumeOffer.IdOffer;

            // Vérification des conditions particulières
            $scope.resumeOffer.LstGroupConditionsSelectedValues = [];
            if ($scope.withConditions) {
                $scope.errorMessage = conditionsService.CheckAndSetGroupConditions($scope.conditionParticulars, $scope.resumeOffer.LstGroupConditionsSelectedValues);
                if ($scope.errorMessage)
                    return;
            }

            // Vérification de la variété
            if ($scope.resumeOffer.LstVarieties.length > 0 && !$scope.resumeOffer.IdVariety) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterAVariety.Content;
                return;
            }
            else if ($scope.resumeOffer.LstVarieties.length > 0)
                $scope.selectedVariety = $filter('filter')($scope.resumeOffer.LstVarieties, { IdVariety: $scope.resumeOffer.IdVariety }, true)[0];

            // Vérification des hectares du contrat
            offerService.CheckIsValidHectaresCurrent($scope.resumeOffer.SurfaceCurrent, $scope.SurfaceMin, $scope.SurfaceMax, $scope.resumeOffer.QuantityLeft, $scope.resumeOffer.Quotity, function (error) {
                $scope.errorSurfaceMessage = error.substring(1, error.length - 1); // Permet de supprimer les guillemets en début et fin de message
                if ($scope.errorSurfaceMessage)
                    return;

                goToStep("validation");
            });
        };

        $scope.ValidateConnection = function () {
            if (!$scope.selectedConnection.Connection.IdConnection) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.ChooseContacte_OfferSubscribe_ListEmpty.Content;
                return;
            }
            $scope.showConfirmButton = false;
            $scope.isConfirmed = true;
            $scope.errorMessage = "";
            $scope.mobileNumberToSendSms = $scope.selectedConnection.Connection.MobileNumber;

            // Création de la vm du contrat
            var newContractVm = getContractVm();

            if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active)
                checkContractAndSendSmsForWithoutThirdParty(newContractVm);
            else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                checkContractAndSendSmsForYouSign(newContractVm);
        };

        $scope.Confirm = function () {
            $scope.isConfirmed = true;
            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.counter.pause = true;

            if ($rootScope.idConnectionLogged)
                $scope.selectedConnection.Connection.IdConnection = $rootScope.idConnectionLogged;

            // Vérification de l'acceptation des condition générale de vente
            if (!$scope.termsAndConditions.isChecked) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseAcceptGeneralConditionsOfSaleBeforeValidation.Content;
                $scope.isConfirmed = false;
                $scope.counter.pause = false;
                return;
            }

            // Création de la vm du contrat
            var newContractVm = getContractVm();

            if ($scope.step === "validation") {
                // Gestion des contacts
                if ($scope.signingEnabled && parametersFactory.Parameters.bUseTableConnections.Value === "1" && !$scope.selectedConnection.Connection.IdConnection)
                    getListConnections();
                // Envoi signature YOUSIGN
                else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                    checkContractAndSendSmsForYouSign(newContractVm);
                // Envoi signature SANS TIERS DE CONFIANCE
                else if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active)
                    checkContractAndSendSmsForWithoutThirdParty(newContractVm);
                else
                    SaveNewContrat(newContractVm);
            }
            else if ($scope.step === "signatureContract") {
                // Vérification du code pour signature SANS TIERS DE CONFIANCE et sauvegarde du contrat
                if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active)
                    checkSmsCodeAndSaveContractForWithoutThirdParty(newContractVm);
                // Vérification du code pour signature YOUSIGN et mise à jour du contrat
                else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                    checkSmsCodeForYouSign();
            }
        };

        $scope.ConditionParticularsOnChange = function (condition) {
            conditionsService.ConditionParticularsOnChange($scope.resumeOffer.IdOffer, condition);
        };

        $scope.AutocompletConditionParticularsOnChange = function (selectedValue, condition) {
            condition.selectedValue = selectedValue.originalObject.IdTreeCondition;
            $scope.conditionSelected = condition;
            conditionsService.ConditionParticularsOnChange($scope.resumeOffer.IdOffer, condition);
        };

        $scope.AutocompleteCondPartSearch = function (str, LstOptions) {
            var matches = [];
            LstOptions.forEach(function (option) {
                if ((option.Label.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)) {
                    matches.push(option);
                }
            });
            return matches;
        };

        $scope.inputChanged = function (str) {
            if ($scope.conditionSelected) {
                $scope.conditionSelected.LstConditionParticularsChilds = [];
            }
        };

        $scope.SendSecurityCode = function () {
            $scope.security.Code = "";

            if ($rootScope.ModuleElectronicSignature.Active && $rootScope.activateSignature) {
                var idConnection = $scope.selectedConnection.Connection.IdConnection ? $scope.selectedConnection.Connection.IdConnection : "";
                signatureService.SendSmsForElectronicSignature($scope.timeToContracting, "", idConnection, function (res) {
                    $scope.isToLate = res.isToLate;
                    $scope.errorMessage = res.errorMessage;
                    $scope.successMessage = res.successMessage;
                });
            }
            else if ($scope.moduleYousignIsActive && $rootScope.activateSignature) {
                signatureService.ResendCodeYouSign($scope.signatureYouSign.Signature.MemberId, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.errorMessage = data[0].errors[0].Message;
                            $scope.isConfirmed = false;
                        }
                    }
                    else {
                        if (data) {
                            $scope.newAuthenticationId = data.AuthenticationId;
                            $scope.errorMessage = "";
                            $scope.successMessage = $rootScope.editableObjectsFactory.EditableObjects.Success_OfferSubscribe_CodeResend.Content;
                        }
                    }
                });
            }
        };

        $scope.Cancel = function () {
            $location.path("user/offers/view/" + enumFactory.TypeOffer.EngagementSurface);
        };

        $rootScope.onRefresh = function () {
            init();
        };

        function goToStep(step) {
            $scope.step = step;
            moveVehicle();
        }

        function getContractVm() {
            return {
                IdContractState: 1,
                IdTypeContract: enumFactory.TypeContract.EngagementSurface,
                IdTypeOrder: enumFactory.TypeOrders.EngagementSurface.IdTypeOrder,
                IdTypeCover: $scope.resumeOffer.IdTypeCover,
                IdOffer: $scope.idOfferSelected,
                IdCrop: $scope.resumeOffer.IdCrop,
                IdProduct: $scope.resumeOffer.IdProduct,
                Quantity: $scope.resumeOffer.SurfaceCurrent,
                IdVariety: $scope.resumeOffer.IdVariety,
                Surface: $scope.resumeOffer.surface,
                LstTreeUserGroupConditionSelectedVms: $scope.resumeOffer.LstGroupConditionsSelectedValues,
                PaymentDateString: $rootScope.User.IsSuperTc && $rootScope.paramNameCoop === enumFactory.Coops.Ducroquet ? $scope.resumeOffer.PaymentDateString : null,
                IdConnection: $scope.selectedConnection.Connection.IdConnection
            };
        }

        function checkContractAndSendSmsForWithoutThirdParty(newContractVm) {
            signatureService.CheckAndSendSmsForElectronicSignature(newContractVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    $scope.counter.pause = false;
                    $scope.isConfirmed = false;
                    $scope.security.Code = "";
                    $scope.security.CodeSendingAway = false;
                    $scope.showConfirmButton = true;
                    $scope.showListChooseContact = false;
                    goToStep("signatureContract");
                }
            });
        }

        function checkContractAndSendSmsForYouSign(newContractVm) {
            contractsService.SaveNewContractAndSendSmsYouSign(newContractVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isToLate = true;
                        $scope.isConfirmed = false;
                        $scope.counter.pause = true;
                    }
                } else {
                    $scope.isConfirmed = true;
                    $scope.idContractSurfaceCommitment = data.IdContract;
                    $scope.hashContract = data.HashContract;   
                    if (data) {
                        if (data.CanAvoidSigning) {
                            $scope.showConfirmButton = true;
                            $scope.counter = {};
                            $scope.counter.start = false;
                            goToStep("confirmation");
                        }
                        else {
                            $scope.showConfirmButton = true;
                            $scope.showListChooseContact = false;
                            $scope.signatureYouSign = data;
                            $scope.isConfirmed = false;
                            goToStep("signatureContract");
                        }
                    }
                }
            });
        }

        function getListConnections() {
            signatureService.GetListConnection($rootScope.selectedClientFromTcForSubscribe.IdUser, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    $scope.listConnection = data;
                    if ($scope.listConnection.length === 0)
                        $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.ChooseContacte_OfferSubscribe_ListEmpty_NoBinding_Contacte.Content;
                    else if ($scope.listConnection.length === 1)
                    {
                        $scope.selectedConnection.Connection.IdConnection = $scope.listConnection[0].IdConnection;
                        $scope.ValidateConnection();
                        return;
                    }
                    $scope.isConfirmed = false;
                    $scope.showListChooseContact = true;
                    goToStep("signatureContract");
                }
            });
        }

        function checkSmsCodeAndSaveContractForWithoutThirdParty(newContractVm) {
            var idContract = "";
            if ($scope.idContractSurfaceCommitment)
                idContract = $scope.idContractSurfaceCommitment;

            signatureService.CheckSecurityCodeFirmOrCommitment($scope.security.Code, idContract, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    newContractVm.IsSignedWithoutThirdParty = true;
                    SaveNewContrat(newContractVm);
                }
            });
        }

        function checkSmsCodeForYouSign() {
            $scope.isConfirmed = true;
            $scope.showConfirmButton = false;
            var idConnection = $scope.selectedConnection.Connection.IdConnection ? $scope.selectedConnection.Connection.IdConnection : "";
            signatureService.SignatureWithCodeYouSign($scope.signatureYouSign.Signature.FilesIds[0], $scope.newAuthenticationId ? $scope.newAuthenticationId : $scope.signatureYouSign.Signature.AuthenticationId, $scope.security.Code, idConnection, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    if (data && (data === "true" || data === "1")) {
                        $scope.errorMessage = "";
                        goToStep("confirmation");
                    }
                    else {
                        $scope.successMessage = "";
                        $scope.showConfirmButton = true;
                        $scope.isConfirmed = false;
                        if (!$scope.security.Code)
                            $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterCodeReceivedBySms.Content;
                        else
                            $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_CodeEnteredNotCorrespondToCodeSended.Content;
                    }
                }
            });
        }

        init();
    }]);
