AgrimarketControllers.controller('commandsController', [
    '$scope', 'enumFactory', 'colonnes', '$rootScope', '$location', 'commandService', 'parametersFactory', 'configurationFactory', 'currentUserFactory', '$routeParams', '$filter',
    function ($scope, enumFactory, colonnes, $rootScope, $location, commandService, parametersFactory, configurationFactory, currentUserFactory, $routeParams, $filter) {

        if (!$rootScope.ModuleMyAccount.Active)
            $location.path('/login');

        $scope.isDisplayPDFColumn = (parametersFactory.Parameters.bDisplayPDFCommand.Value === '1');

        $scope.dateStartSelected = "";
        $scope.dateEndSelected = "";

        $scope.ApplyCommandDates = function () {
            Init();
        };

        var Init = function () {
            $scope.buttonCommandDetails = $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Commands_Label_CommandDetails.Content;
            $scope.otherArticles = $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Commands_Label_Search_OtherArticles.Content;
            $scope.qtyOrdered = $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Commands_Label_QtyOrdered.Content;
            $scope.orderDate = $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Commands_Label_OrderDate.Content;
            $scope.commandState = $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Commands_Label_CommandState.Content;
            $scope.orderNumber = $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Commands_Label_OrderNumber.Content;

            $scope.groupArticleState = {
                1: $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Commands_State_Article_Delivered.Content,
                2: $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Commands_State_Article_Not_Delivered.Content,
                3: $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Commands_State_Article_Partially_Delivered.Content
            };

            $scope.groupArticle = [];
            $scope.tableCommandsLabels = colonnes.data.ColumnName.split(',');
            $scope.tableCommandsDatasVisible = colonnes.data.DataVisible.split(',');
            $scope.data = {
                reverse: false,
                sortKey: "LineNumberInt",
                pageNo: 1,
                max: 10
            };
            $scope.errorMessage = "";
            $scope.searchText = "";
            $scope.operations = {};
            $scope.idOperationSelected = 0;
            $scope.All = $rootScope.editableObjectsFactory.EditableObjects.AllItems_F.Content;
            $scope.commandCurrent = "";
            $scope.commandDeliveryStates = [];
            $scope.idCommandDeliveryStateSelected = 0;
            $scope.paramIdExtCommand = $routeParams.idExtCommand;

            // Enum de l'état d'un article dans une commande
            $scope.enumStateArticle = enumFactory.StateArticle;

            $scope.GetFiltersByDates();
        };


        $scope.showDetailsArticle = function (command) {
            $scope.data = {
                total: command ? command.totalArticles : 0,
                reverse: false,
                sortKey: "LineNumberInt",
                pageNo: 1,
                max: 10
            };
            var oldCommandCurrent = angular.copy($scope.commandCurrent);
            command.ShowTableDetail = !command.ShowTableDetail;
            $scope.commandCurrent = command;

            if (command.ShowTableDetail && command.OrderNumber !== oldCommandCurrent.OrderNumber)
                $scope.GetData(1, command);

            $scope.groupArticle.forEach(function (item) {
                if (item.OrderNumber !== command.OrderNumber)
                    item.ShowTableDetail = false;
            });
        };

        $scope.ExportCommands = function () {
            var downloadPath = commandService.GetCommandsXls(toDate($scope.dateStartSelected), toDate($scope.dateEndSelected), $scope.idOperationSelected, currentUserFactory.User.IdClientSelectedFromTc ? currentUserFactory.User.IdClientSelectedFromTc : currentUserFactory.User.IdUser, $scope.idCompagnySelected, $rootScope.language.IdLanguage);
            window.open(downloadPath, '_blank', '');
        };


        $scope.GetFiltersByDates = function () {
            $scope.errorMessage = "";
            commandService.GetFiltersByDates(toDate($scope.dateStartSelected), toDate($scope.dateEndSelected), function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = data;     
                }
                else {

                    $scope.dateStartSelected = data.DateStart;
                    $scope.dateEndSelected = data.DateEnd;

                    $scope.commandDeliveryStates = data.CommandDeliveryStates;
                    if ($scope.commandDeliveryStates.length > 1)
                        $scope.commandDeliveryStates.unshift({ Id: 0, Label: $scope.All });
                    if ($scope.commandDeliveryStates.length > 0)
                        $scope.idCommandDeliveryStateSelected = $scope.commandDeliveryStates[0].Id;

                    $scope.compagnies = data.Compagnies;
                    $scope.compagnies.forEach(function (item) {
                        if (!item.Id)
                            item.Id = "";
                    });

                    if ($scope.compagnies.length > 1)
                        $scope.compagnies.unshift({ Id: -1, Label: $scope.All });

                    var compagnieIsDefault = $scope.compagnies.find(function (compagnie) {
                        return compagnie.IsDefault === true;
                    });

                    if ($scope.compagnies.length > 0) {
                        if (compagnieIsDefault)
                            $scope.idCompagnySelected = compagnieIsDefault.Id;
                        else
                            $scope.idCompagnySelected = $scope.compagnies[0].Id;
                    }

                    $scope.GetOperationCommands();
                }
            });
        };

        $scope.GetCommandsPaginateWithFilter = function () {
            $scope.errorMessage = "";
            $scope.groupArticle = [];
            $scope.idCompagny = $scope.idCompagnySelected ? $scope.idCompagnySelected : "";
            $scope.loadingCommands = true;
            commandService.GetCommandsPaginateWithFilter(toDate($scope.dateStartSelected), toDate($scope.dateEndSelected), $scope.idOperationSelected, $scope.idCompagny, $scope.searchText, $scope.idCommandDeliveryStateSelected, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = data;
                }
                else {
                    data.forEach(function (item) {
                        item.totalArticles = item.Articles.length;
                    });

                    $scope.groupArticle = data;
                    if ($scope.paramIdExtCommand) {
                        var paramCommandSelected = $filter('filter')($scope.groupArticle, { OrderNumber: $scope.paramIdExtCommand }, true)[0];
                        if (paramCommandSelected) {
                            paramCommandSelected.ShowTableDetail = true;
                        }
                        $scope.paramIdExtCommand = "";
                        $scope.commandCurrent = paramCommandSelected;
                    }
                    $scope.loadingCommands = false;
                }
            });
        };

        $scope.GetOperationCommands = function () {
            $scope.errorMessage = "";
            $scope.loadingOperations = true;
            commandService.GetOperationCommands(toDate($scope.dateStartSelected), toDate($scope.dateEndSelected), $scope.idCompagnySelected ? $scope.idCompagnySelected : "", $scope.paramIdExtCommand , function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = data;
                }
                else {
                    $scope.operations = data.operations;
                    $scope.idOperationSelected = data.idOperationSelected;
                    if ($scope.operations.length > 0) {
                        if (!$scope.idOperationSelected) {
                            $scope.idOperationSelected = $scope.operations[0].IdOperation;
                        }
                        if ($scope.idOperationSelected) {
                            $scope.GetCommandsPaginateWithFilter();
                        }
                    }
                    $scope.loadingOperations = false;
                }
            });
        };

        $scope.GetData = function (pageNo, command) {
            $scope.data.pageNo = pageNo;
            if (command.ShowTableDetail)
                getArticles(command);
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.data.sortKey = sortKey;
            $scope.data.reverse = reverse;
            getArticles($scope.commandCurrent);
        };

        $scope.ChangeOperation = function (idOperation) {
            $scope.commandCurrent = null;
            $scope.idOperationSelected = idOperation;
            $scope.GetCommandsPaginateWithFilter();
        };

        $scope.$watch("searchText", function (newValue, oldValue) {
            if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                $scope.GetCommandsPaginateWithFilter();
        });

        function toDate(date) {
            if (!date) return "";
            var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
            var dt = new Date(date.replace(pattern, '$3-$2-$1'));
            var currentDate = new Date(dt.toString());
            return moment(currentDate).format('YYYY/MM/DD');
        }

        var getArticles = function (command) {
            $scope.loadingArticles = true;
            commandService.GetArticlesFromCommandPaginate(command.OrderNumber, $scope.data.sortKey, $scope.data.reverse, $scope.data.pageNo, $scope.data.max, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = data;
                }
                else {
                    $scope.commandCurrent.Articles = data;
                    $scope.data.total = $scope.commandCurrent.totalArticles;
                    $scope.loadingArticles = false;
                }
            });
        };

        Init();
    }
]);