AgrimarketControllers.controller('backdoorController', [
    '$scope', '$location', '$rootScope', 'backdoorService', 'enumFactory', 'configurationFactory', function ($scope, $location, $rootScope, backdoorService, enumFactory, configurationFactory) {

        $scope.loginbackdoorErrorMessage = "";
        $rootScope.AuthorizedBackdoor = false;
        $rootScope.skin = configurationFactory.Skin;
        $rootScope.loading = false;

        if ($location.protocol().indexOf("https") !== -1) {
            for (var url in configurationFactory.Url) {
                configurationFactory.Url[url] = configurationFactory.Url[url].replace("http:", "https:");
            }
        }

        $scope.LogToBackdoor = function () {
            $rootScope.loading = true;
            if (($scope.backPassword !== null && $scope.backPassword !== undefined) && ($scope.backLogin !== null && $scope.backLogin !== undefined)) {
                backdoorService.LogBackdoor($scope.backLogin, $scope.backPassword, function(dataProfile, statusProfile) {
                    if (statusProfile !== null) {
                        if (statusProfile === 400) {
                            $scope.loginbackdoorErrorMessage = dataProfile[0].errors[0].Message;
                            $scope.backPassword = null;
                            $rootScope.loading = false;
                        } else {
                            return false;
                        }
                    } else {
                        $rootScope.AuthorizedBackdoor = true;

                        sessionStorage.setItem("UsersActived", dataProfile.NbUserActived);
                        sessionStorage.setItem("LOG_AVIV", true);
                        var allUsers = [];
                        var allTc = [];
                        var allAdmin = [];

                        //Insert a item to the first index into the list
                        dataProfile.User.unshift({
                            "IdUser": 0,
                            "FullName": '------ Sélectionner ------'
                        });

                        
                        dataProfile.User.forEach(function (item) {
                        	//User profile
                            if (item.IdRole === enumFactory.Roles.User || item.IdUser === 0)
                                allUsers.push(item);
                            //Tc profile
                            if (item.IdRole === enumFactory.Roles.TC || item.IdUser === 0)
                                allTc.push(item);
							//Admin profile
                            if (item.IdRole === enumFactory.Roles.Admin || item.IdUser === 0)
                                allAdmin.push(item);
                        });

                        $rootScope.allUsers = allUsers;
                        $rootScope.allTc = allTc;
                        $rootScope.allAdmin = allAdmin;
                        $location.path("backdoor/profile");
                    }
                });
            } else {
                $scope.loading = false;
                $scope.loginbackdoorErrorMessage = "Veuillez renseigner un login/mot de passe";
            }
        };

        $scope.resetErrorMessage = function () {
            if ($scope.loginbackdoorErrorMessage.length > 0)
                $scope.loginbackdoorErrorMessage = "";
        };

    }]);