AgrimarketControllers.controller('contentUserController', ['$scope', '$rootScope', '$http', '$location', '$sce', 'currentUserFactory', 'configurationFactory', '$log', '$timeout', 'contentService', '$routeParams',
    function ($scope, $rootScope, $http, $location, $sce, currentUserFactory, configurationFactory, $log, $timeout, contentService, $routeParams) {

        if (!$rootScope.ModuleNews.Active)
            $location.path('/');

        var idPage = $routeParams.IdPage;
        $scope.errorMessage = "";

        var Init = function() {
            contentService.GetPageContent(idPage, function(data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage =data[0].errors[0].Message;
                } else {
                    if (data !== undefined && data !== null) {
                        $scope.content = data;

                        $scope.content.CurrentPage.Content = $sce.trustAsHtml($scope.content.CurrentPage.Content);
                    }
                }
            });
        };

        Init();

    }]);
