AgrimarketControllers.controller('commitmentsAllController', [
    '$scope', '$rootScope', '$routeParams', '$http', '$location', 'contractsService', '$filter', 'colonnesCropProductTonnages', 'enumFactory',
    function ($scope, $rootScope, $routeParams, $http, $location, contractsService, $filter, colonnesCropProductTonnages, enumFactory) {
    
	var init = function () {
        $scope.lstProducts = [];
        $scope.lstSilos = [];
        $scope.lstCrops = [];
        $scope.listCropProductTonnagesFromDataBase = [];
        $scope.idProductSelected = "";
        $scope.labelSiloSelected = "";
        $scope.idCropSelected = "";

        contractsService.GetAllContractsCommitmentAndAllContractsValidated(function (data, status) {
            if (status !== null) {
                if (status === 400) {
                    $scope.errorMessage = data[0].errors[0].Message;
                }
            } else {
                $scope.listCropProductTonnagesFromDataBase = data;  
                $scope.listCropProductTonnages = data;
                $scope.tableCropProductTonnagesDataVisible = colonnesCropProductTonnages.data.DataVisible.split(',');
                $scope.tableCropProductTonnagesLibelle = colonnesCropProductTonnages.data.ColumnName.split(',');
                data.forEach(function (item) {
                    if (!$filter('filter')($scope.lstProducts, { IdProduct: Number(item.IdProduct) }, true)[0]) {
                        $scope.lstProducts.push({ IdProduct: item.IdProduct, LabelProduct: item.LabelProduct });
                    }
                    if (!$filter('filter')($scope.lstSilos, { LabelSilo: item.LabelSilo }, true)[0]) {
                        $scope.lstSilos.push({ LabelSilo: item.LabelSilo });
                    }
                    if (!$filter('filter')($scope.lstCrops, { IdCrop: Number(item.IdCrop) }, true)[0]) {
                        $scope.lstCrops.push({ IdCrop: item.IdCrop, LabelCrop: item.LabelCrop });
                    }

                    if ($scope.lstProducts && $scope.lstProducts.length == 1) {

                        $scope.idProductSelected = $scope.lstProducts[0].IdProduct;
                    } else {
                        $scope.idProductSelected = "";
                    }

                    if ($scope.lstSilos && $scope.lstSilos.length == 1) {
                        $scope.labelSiloSelected = $scope.lstSilos[0].LabelSilo;
                    } else {
                        $scope.labelSiloSelected = "";
                    }
                    
                    if ($scope.lstCrops && $scope.lstCrops.length == 1) {
                        $scope.idCropSelected = $scope.lstCrops[0].IdCrop;
                    } else {
                        $scope.idCropSelected = "";
                    }
                });
                setTotalRow();
            }
        });
	};

        $scope.Filters = function () {
            $scope.listCropProductTonnages = angular.copy($scope.listCropProductTonnagesFromDataBase);
            if ($scope.idProductSelected) {
                $scope.listCropProductTonnages = $filter('filter')($scope.listCropProductTonnages, { IdProduct: Number($scope.idProductSelected) }, true);
            }
            if ($scope.labelSiloSelected) {
                $scope.listCropProductTonnages = $filter('filter')($scope.listCropProductTonnages, { LabelSilo: $scope.labelSiloSelected }, true);
            }
            if ($scope.idCropSelected) {
                $scope.listCropProductTonnages = $filter('filter')($scope.listCropProductTonnages, { IdCrop: Number($scope.idCropSelected) }, true);
            }
            setTotalRow();
        };

        var setTotalRow = function () {
            $scope.totalTonnageEngaged = 0;
            $scope.totalTonnageFixed = 0;
            $scope.totalTonnageToFix = 0;
            $scope.listCropProductTonnages.forEach(function (item) {
                $scope.totalTonnageEngaged += item.TonnageEngaged;
                $scope.totalTonnageFixed += item.TonnageFixed;
                $scope.totalTonnageToFix += item.TonnageToFix;
            });
        };

        init();

        $rootScope.onRefresh = function () {
            init();
        };
}
]);