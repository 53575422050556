AgrimarketControllers.controller('manageUsersController', ['$scope', '$rootScope', '$sce', '$filter', '$location', 'colonnes', 'userService', 'groupContactService', 'enumFactory', 'dateService', 'productsService', 'modulesFactory', 'compagnyService', '$timeout', 'configurationFactory',
    function ($scope, $rootScope, $sce, $filter, $location, colonnes, userService, groupContactService, enumFactory, dateService, productsService, modulesFactory, compagnyService, $timeout, configurationFactory) {

        $rootScope.lstUsersToMail = [];
        $scope.errorMessage = "";
        $scope.searchText = "";
        $scope.PopupDetailUserTitle = "";
        $scope.currentUser = {};
        $scope.max = 10;
        $scope.pageNo = 1;
        $scope.isActif = enumFactory.DropDownList.Tous;
        $scope.userActionsList = enumFactory.TypeUserActionsList;
        $scope.showButtonConfirmPopUp = true;
        $scope.userAction = {
            IdTypeAction: null,
            LstIdUser: [],
            StartSubscriptionDate: null,
            EndSubscriptionDate: null
        };
        $scope.data = {};
        $scope.data.reverse = false;
        $scope.data.sortKey = "LastName";
        $scope.allSelected = false;
        $scope.idCompagnySelected = "";
        $scope.idGroupContactSelected = "";

        $scope.SelectAll = function () {
            if ($scope.allSelected) {
                var actif = $scope.isActif === "-1" ? "" : $scope.isActif;
                if (!$scope.idGroupContactSelected) $scope.idGroupContactSelected = "";

                userService.GetUserId($scope.userAction.IdRole, $scope.data.sortKey, $scope.data.reverse, $scope.searchText, actif, $scope.idCompagnySelected, $scope.idGroupContactSelected, function (data2, status2) {
                    if (status2 !== null) {
                        if (status2 == 400)
                            $scope.errorMessage = data2[0].errors[0].Message;
                        else
                            $scope.errorMessage = data2;
                    } else {
                        $scope.userAction.LstIdUser = data2;
                    }
                });
            } else {
                $scope.userAction.LstIdUser = [];
            }
        };

        $scope.SelectUser = function (idUser) {
            var index = $scope.userAction.LstIdUser.indexOf(idUser);
            if (index >= 0) {
                $scope.userAction.LstIdUser.splice(index, 1);
            } else {
                $scope.userAction.LstIdUser.push(idUser);
            }
        };

        $scope.LoadDataUser = function (unSelectAllUsers) {
            if (unSelectAllUsers) {
                $scope.userAction.LstIdUser = [];
                $scope.allSelected = false;
            }
            var actif = $scope.isActif == "-1" ? "" : $scope.isActif;
            if (!$scope.idGroupContactSelected) $scope.idGroupContactSelected = "";
            if (!$scope.idCompagnySelected) $scope.idCompagnySelected = "";
            userService.GetUserByIdRolePaginate($scope.userAction.IdRole, $scope.max, $scope.pageNo, $scope.data.sortKey, $scope.data.reverse, $scope.searchText, actif, $scope.idCompagnySelected, $scope.idGroupContactSelected, function (data2, status2) {
                if (status2 !== null) {
                    if (status2 == 400) 
                        $scope.errorMessage = data2[0].errors[0].Message;
                    else
                        $scope.errorMessage = data2;
                } else {
                    $scope.lstUsers = data2.ListVm;
                    $scope.allUsers = data2.AllUsersWithoutPaginate;
                    loadString(data2.ListVm);
                    $scope.total = data2.Total;
                }
            });
        };
        var refreshUsers = null; // On crée un timer car si on tape trop vite dans le champ de recherche le listing ne correspond pas au nom recherché
        $scope.$watch("searchText", function (newValue, oldValue) {
            if (newValue !== undefined && newValue !== null && oldValue != newValue) {
                if (newValue.length >= 3 || newValue.length < oldValue.length) {
                    if (refreshUsers === null)
                        refreshUsers = setTimeout(function () { $scope.LoadDataUser(true); }, 800);
                    else {
                        clearTimeout(refreshUsers);
                        refreshUsers = setTimeout(function () { $scope.LoadDataUser(true); }, 800);
                    }
                }
            }
        });

        if ($location.path() == "/admin/manage/users") {
            $scope.actionAdmin = "Ajouter un agriculteur";
            $scope.userCategory = "Agriculteur";
            $scope.userAction.IdRole = enumFactory.Roles.User;
            $scope.iCurrent = 0;
            $scope.userActionsList = angular.copy($filter('filter')($scope.userActionsList, function (item) {
                return (item.Value != enumFactory.TypeUserActions.ActiverConnexionAgri && item.Value != enumFactory.TypeUserActions.DesactiverConnexionAgri && (!$rootScope.paramBUseTableConnections || item.Value != enumFactory.TypeUserActions.EnvoyerIdentifiants));
            }, true));
        }
        else if ($location.path() == "/admin/manage/tc") {
            $scope.actionAdmin = "Ajouter un TC";
            $scope.userCategory = "TC";
            $scope.userAction.IdRole = enumFactory.Roles.TC;
            $scope.iCurrent = 1;
            $scope.userActionsList = angular.copy($filter('filter')($scope.userActionsList, function (item) {
                return (item.Value != enumFactory.TypeUserActions.ChangerDatesAbonnement && item.Value != enumFactory.TypeUserActions.GroupeUtilisateur);
            }, true));
        }
        else {
            $location.path('/');
        }

        //charge les données user en fonction de l'IdRole user
        $scope.LoadDataUser();

        var init = function () {
            $scope.tableDataVisible = colonnes.data.DataVisible.split(',');
            $scope.tableLibelle = colonnes.data.ColumnName.split(',');

            if ($rootScope.ModuleMultipleCompagnies.Active) {
                compagnyService.GetAll(function (data, status) {
                    if (status === null)
                        $scope.lstCompagnies = data;
                    else if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else {
                        $scope.errorMessage = status;
                    }
                });
            }

            groupContactService.GetAll(function (data, status) {
                if (status === null)
                    $scope.lstGroupContacts = data;
                else if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });


        };

        $scope.ShowDetail = function (item, popup) {
            $scope.potentialAction = "Fermer";
            $scope.currentUserFactory = {};
            $scope.PopupDetailUserTitle = "Détails de " + item.FullName;
            $scope.currentUser = item;
            popup();
        };


        $scope.UpdateUserPopup = function (item, index, popup) {
            $scope.potentialAction = "Valider";
            $scope.currentUser = {};
            $scope.groupContactUserList = {};
            $scope.errorPopupHtml = "";
            $scope.errorMessage = "";
            $scope.succesMessage = "";
            $scope.modalTitle = "Modifier un " + $scope.userCategory;
            $scope.popupAction = "Modifier";
            $scope.keyAction = "Up";
            $scope.currentUser = angular.copy(item);
            if (($scope.currentUser.StartDateSubscription !== null && $scope.currentUser.StartDateSubscription !== undefined)) {
                $scope.currentUser.StartDateSubscription = $filter('date')($scope.currentUser.StartDateSubscription, "dd/MM/yyyy");
            }
            if (($scope.currentUser.EndDateSubscription !== null && $scope.currentUser.EndDateSubscription !== undefined)) {
                $scope.currentUser.EndDateSubscription = $filter('date')($scope.currentUser.EndDateSubscription, "dd/MM/yyyy");
            }

            $scope.currentUser.lstCompagniesForUser = angular.copy($scope.lstCompagnies);

            if ($rootScope.ModuleMultipleCompagnies.Active) {
                $scope.currentUser.lstCompagniesForUser.forEach(function (item) {
                    item.checked = $filter('filter')($scope.currentUser.LstIdCompagny, function (id) { return id === item.IdCompagny; }, true)[0] !== undefined;
                });
            }

            popup();
        };

        $scope.SaveUser = function () {
            if ($scope.userCategory === "TC") {
                $scope.currentUser.IdRole = parseInt(enumFactory.Roles.TC);
            } else {
                $scope.currentUser.IdRole = parseInt(enumFactory.Roles.User);
                $scope.currentUser.CanConnectAsUser = false;
            }
            userService.SaveUser($scope.currentUser, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        if (($scope.currentUser.StartDateSubscription !== null && $scope.currentUser.StartDateSubscription !== undefined)) {
                            $scope.currentUser.StartDateSubscription = $filter('date')($scope.currentUser.StartDateSubscription, "dd/MM/yyyy");
                        }
                        if (($scope.currentUser.EndDateSubscription !== null && $scope.currentUser.EndDateSubscription !== undefined)) {
                            $scope.currentUser.EndDateSubscription = $filter('date')($scope.currentUser.EndDateSubscription, "dd/MM/yyyy");
                        }
                        var errorHtml = data[0].errors[0].Message;
                        $scope.errorPopupHtml = $sce.trustAsHtml(errorHtml);
                        $scope.openPopUpUser();
                    }
                } else {
                    switch ($scope.popupAction) {
                        case "Ajouter":
                            $scope.succesMessage = "Votre " + $scope.userCategory + " a bien été pris en compte";
                            break;
                        case "Modifier":
                            $scope.succesMessage = "Vos modifications ont bien été prises en compte";
                            break;
                        default:
                    }
                    //recharge les dataUsers
                    $scope.LoadDataUser();
                    $scope.currentUser = {};
                }
            });
        };

        $scope.AddUserPopup = function (popup) {
            $scope.errorMessage = "";
            $scope.succesMessage = "";
            $scope.currentUser = {};
            $scope.groupContactUserList = {};
            $scope.errorPopupHtml = "";
            $scope.modalTitle = "Ajouter un " + $scope.userCategory;
            $scope.popupAction = "Ajouter";
            $scope.keyAction = "Add";
            $scope.currentUser.lstCompagniesForUser = angular.copy($scope.lstCompagnies);

            if ($rootScope.ModuleMultipleCompagnies.Active) {
                $scope.currentUser.lstCompagniesForUser.forEach(function (item) {
                    item.checked = false;
                });
            }
            popup();
        };

        $scope.DeleteUser = function (item, index, popup) {
            $scope.PopupDeleteUserSelectedTitle = "Supprimer un " + $scope.userCategory;
            $scope.currentUser = angular.copy(item);
            $scope.checkIfOrderInExecution(popup);
        };

        $scope.checkIfOrderInExecution = function (popup) {
            $scope.showButtonConfirmPopUp = true;
            var ceOrCet = "cet";
            if ($scope.userCategory === "TC")
                ceOrCet = "ce";

            userService.CheckExistOrderUserInExecution($scope.currentUser.IdUser, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    } else {
                        $location.path('/');
                    }
                } else {
                    if (data.length !== null && data.length > 0) {
                        $scope.showButtonConfirmPopUp = false;
                        var contractString = " le contrat suivant est";
                        if (data.length > 1) {
                            contractString = " les " + data.length + " contrats suivants sont";
                        }
                        var idsContractsInExecution = "";
                        data.forEach(function (item) {
                            idsContractsInExecution += item.IdContract.toString() + ", ";
                        });
                        idsContractsInExecution = idsContractsInExecution.substr(0, idsContractsInExecution.length - 2);
                        $scope.messageConfirmPopup = "Impossible de supprimer " + ceOrCet + " " + $scope.userCategory.toLowerCase() + " car " + contractString + " en cours d'exécution : " + idsContractsInExecution + ".";
                    } else {
                        $scope.messageConfirmPopup = "Êtes-vous sûr de vouloir supprimer " + ceOrCet + " " + $scope.userCategory + "?";
                    }
                    popup();
                }
            });
        };

        $scope.DeleteLogicallyUserSelected = function () {
            userService.DeleteLogicallyUser($scope.currentUser.IdUser, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    } else {
                        $location.path('/');
                    }
                } else {
                    $scope.succesMessage = "Votre suppression a bien été prise en compte";
                    $scope.LoadDataUser();
                    $scope.currentUser = {};
                }
            });
        };

        $rootScope.onRefresh = function () {
            init();
        };
        init();

        function loadTcUserForAutoCompletionPopUp(popup) {
            userService.GetUserByIdRole(enumFactory.Roles.TC, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.lstTc = data;
                    $scope.selectedAutoCompleteObject = {};
                    $scope.selectedAutoCompleteInput = '';
                    $timeout(function () {
                        popup();
                    }, 0);
                }
            });
        }

        $scope.VerifAction = function (popupChangerDatesAbonnement, popupGroupeUtilisateur, popUpLinkTC) {
            $scope.errorMessage = $scope.succesMessage = "";
            if ($scope.userAction.IdTypeAction === null) {
                $scope.errorMessage = "Veuillez sélectionner une action.";
            }
            else if ($scope.userAction.LstIdUser.length === 0) {
                $scope.errorMessage = "Veuillez sélectionner un utilisateur.";
            }
            else if ($scope.userAction.IdTypeAction === enumFactory.TypeUserActions.ChangerDatesAbonnement) {
                $scope.errorMessagePopup = "";
                $scope.userAction.StartSubscriptionDate = "";
                $scope.userAction.EndSubscriptionDate = "";
                popupChangerDatesAbonnement();
            }
            else if ($scope.userAction.IdTypeAction === enumFactory.TypeUserActions.EnvoyerMail) {
                var lstUsersSelected = [];
                $scope.userAction.LstIdUser.forEach(function (idUser) {
                    var users = $filter('filter')($scope.allUsers, function (item) { return item.IdUser === idUser; }, true);
                    if (users.length === 1)
                        lstUsersSelected.push(users[0]);
                });
                if (!$rootScope.Modules.ModuleMessagerie.Active) {
                    var href = "mailto:";
                    lstUsersSelected.forEach(function (user) {
                        href += user.Mail + ";";
                    });
                    window.location.href = href;
                    $scope.LoadDataUser(true);
                } else {
                    $rootScope.lstUsersToMail = lstUsersSelected;
                    $location.path("/common/messenger/newmessage");
                }
            }
            else if ($scope.userAction.IdTypeAction === enumFactory.TypeUserActions.GroupeUtilisateur) {
                $scope.errorMessagePopup = "";
                $scope.userAction.GroupUserVmSelected = {
                };
                groupContactService.GetAll(function (data) {
                    $scope.GroupContacts = data;
                    $scope.GroupContacts.splice(0, 0, ({ IdGroupContact: 0, Label: 'Nouveau' }));
                    popupGroupeUtilisateur();
                });

            }
            else if ($scope.userAction.IdTypeAction === enumFactory.TypeUserActions.EnregistrerLiaisonTC) {
                $scope.errorMessagePopup = "";
                $scope.PopUpLinkTCTitle = "Ajouter une liaison avec un TC";
                loadTcUserForAutoCompletionPopUp(popUpLinkTC);
            }
            else
                $scope.DoSelectedAction();
        };

        $scope.VerifSubscriptionDates = function () {
            var jourDebut = parseInt($scope.userAction.StartSubscriptionDate.split('/')[0]);
            var moisDebut = parseInt($scope.userAction.StartSubscriptionDate.split('/')[1]);
            var anneeDebut = parseInt($scope.userAction.StartSubscriptionDate.split('/')[2]);
            var jourFin = parseInt($scope.userAction.EndSubscriptionDate.split('/')[0]);
            var moisFin = parseInt($scope.userAction.EndSubscriptionDate.split('/')[1]);
            var anneeFin = parseInt($scope.userAction.EndSubscriptionDate.split('/')[2]);
            var dateDebut = new Date(anneeDebut, moisDebut - 1, jourDebut);
            var dateFin = new Date(anneeFin, moisFin - 1, jourFin);

            if (dateDebut > dateFin) {
                $scope.errorMessagePopup = "La date de début d'abonnement doit être inférieur à la date de fin.";
                return false;
            }
            return true;
        };

        $scope.VerifUser = function () {
            $scope.isDateUsing = false;
            $scope.isError = false;
            var errorDate = false;

            var errorsPopup = userService.VerifUserLight($scope.currentUser);

            if ($scope.currentUser.Login === undefined || $scope.currentUser.Login === "" || $scope.currentUser.Login === null) {
                errorsPopup += "<li>Veuillez renseigner un identifiant</li>";
                if (!$scope.isError)
                    $scope.isError = true;
            }
            if (($scope.currentUser.StartDateSubscription !== null && $scope.currentUser.StartDateSubscription !== undefined && $scope.currentUser.StartDateSubscription !== "")) {
                var regexDate = /^(\d{2}\/){2}\d{4}$/;
                $scope.isDateUsing = true;
                if ((!(regexDate.test($scope.currentUser.StartDateSubscription))) || (!dateService.VerifDateIsCalendar($scope.currentUser.StartDateSubscription))) {
                    if (!$scope.isDateUsing) {
                        $scope.isDateUsing = true;
                    }
                    errorsPopup += "<li>Veuillez renseigner une date de début d'abonnement valide (format attendu dd/MM/YYYY)</li>";
                    if (!$scope.isError)
                        $scope.isError = true;
                    if (!errorDate)
                        errorDate = true;
                }
            }
            if ($scope.currentUser.EndDateSubscription !== null && $scope.currentUser.EndDateSubscription !== undefined && $scope.currentUser.EndDateSubscription !== "") {
                if (!$scope.isDateUsing) {
                    $scope.isDateUsing = true;
                }
                if ((!dateService.VerifDateIsCalendar($scope.currentUser.EndDateSubscription) || (!dateService.VerifDateIsCalendar($scope.currentUser.EndDateSubscription)))) {
                    errorsPopup += "<li>Veuillez renseigner une date de fin d'abonnement valide (format attendu dd/MM/YYYY)</li>";
                    if (!$scope.isError)
                        $scope.isError = true;
                    if (!errorDate)
                        errorDate = true;
                }
            }
            if (!errorDate) {
                if (($scope.currentUser.StartDateSubscription !== null && $scope.currentUser.StartDateSubscription !== undefined && $scope.currentUser.StartDateSubscription !== "") && ($scope.currentUser.EndDateSubscription !== null && $scope.currentUser.EndDateSubscription !== undefined && $scope.currentUser.EndDateSubscription !== "")) {
                    if (!dateService.compareDate($scope.currentUser.StartDateSubscription, $scope.currentUser.EndDateSubscription)) {
                        errorsPopup += "<li>La date de début d'abonnement doit être inférieur à la date de fin</li>";
                        if (!$scope.isError)
                            $scope.isError = true;
                    }
                }
            }

            if ($scope.currentUser.IsEnabled === undefined || $scope.currentUser.IsEnabled === "") {
                errorsPopup += "<li>Veuillez renseigner l'accès extranet</li>";
                if (!$scope.isError)
                    $scope.isError = true;
            }

            $scope.currentUser.LstIdCompagny = [];
            if ($rootScope.ModuleMultipleCompagnies.Active) {
                $scope.currentUser.lstCompagniesForUser.forEach(function (item) {
                    if (item.checked)
                        $scope.currentUser.LstIdCompagny.push(item.IdCompagny);
                });
            }

            if ($rootScope.ModuleMultipleCompagnies.Active && $scope.currentUser.LstIdCompagny.length === 0) {
                errorsPopup += "<li>Veuillez sélectionner au moins une société</li>";
                if (!$scope.isError)
                    $scope.isError = true;
            }

            if ($scope.isError) {
                $scope.errorPopupHtml = $sce.trustAsHtml(errorsPopup);
                return false;
            } else {
                if ($scope.isDateUsing) {
                    if (($scope.currentUser.StartDateSubscription !== null && $scope.currentUser.StartDateSubscription !== undefined && $scope.currentUser.StartDateSubscription !== "")) {
                        $scope.currentUser.StartDateSubscription = dateService.ConvertDateToDateAPI($scope.currentUser.StartDateSubscription);
                    }
                    if ($scope.currentUser.EndDateSubscription !== null && $scope.currentUser.EndDateSubscription !== undefined && $scope.currentUser.EndDateSubscription !== "") {
                        $scope.currentUser.EndDateSubscription = dateService.ConvertDateToDateAPI($scope.currentUser.EndDateSubscription);
                    }
                }
                return true;
            }
        };

        $scope.VerifGroupeUtilisateur = function () {
            if ($scope.userAction.GroupUserVmSelected.Id !== 0 && !$scope.userAction.GroupUserVmSelected.Id) {
                $scope.errorMessagePopup = "Veuillez sélectionner ou créer un groupe.";
                return false;
            }

            if ($scope.userAction.GroupUserVmSelected.Id === 0 && !$scope.userAction.GroupUserVmSelected.Name) {
                $scope.errorMessagePopup = "Le libellé du groupe est obligatoire.";
                return false;
            }

            if ($scope.userAction.GroupUserVmSelected.Name === "Nouveau" || $scope.userAction.GroupUserVmSelected.Name === "Sélectionnez...") {
                $scope.errorMessagePopup = "Merci de saisir un autre libellé.";
                return false;
            }

            if ($scope.GroupContacts && $scope.GroupContacts.filter(function (element) { return element.Label === $scope.userAction.GroupUserVmSelected.Name; }).length > 0) {
                $scope.errorMessagePopup = "Le libellé du groupe existe déjà.";
                return false;
            }
            return true;
        };

        $scope.DoSelectedAction = function () {
            userService.DoUsersAction($scope.userAction, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                }
                else {
                    switch ($scope.userAction.IdTypeAction) {
                        case enumFactory.TypeUserActions.EnvoyerIdentifiants:
                            $scope.succesMessage = $rootScope.editableObjectsFactory.EditableObjects.Tc_ManageTCUsers_SendIdentifiersSuccessMessage.Content;
                            break;
                        case enumFactory.TypeUserActions.ActiverAcces:
                            $scope.succesMessage = "Le(s) compte(s) ont été activé(s) avec succès";
                            break;
                        case enumFactory.TypeUserActions.DesactiverAcces:
                            $scope.succesMessage = "Le(s) compte(s) ont été désactivé(s) avec succès";
                            break;
                        case enumFactory.TypeUserActions.ChangerDatesAbonnement:
                            $scope.succesMessage = "Les dates d'abonnement ont été modifiées avec succès";
                            break;
                        case enumFactory.TypeUserActions.GroupeUtilisateur:
                            $scope.succesMessage = "Le groupe a été attribué avec succès";
                            break;
                        case enumFactory.TypeUserActions.ActiverConnexionAgri:
                            $scope.succesMessage = "La connexion pour un agriculteur a été activé avec succès";
                            break;
                        case enumFactory.TypeUserActions.DesactiverConnexionAgri:
                            $scope.succesMessage = "La connexion pour un agriculteur a été désactivé avec succès";
                            break;
                        case enumFactory.TypeUserActions.EnregistrerLiaisonTC:
                            $scope.selectedAutoCompleteObject = {};
                            $scope.selectedAutoCompleteInput = '';
                            $scope.succesMessage = "Les liaisons avec le TC ont été ajouté avec succès";
                            break;
                    }
                    $scope.LoadDataUser(true);
                }
            });
        };

        function loadString(users) { //solution temporaire en attendant de trouver une solution à la directive pour afficher les sous-objets
            users.forEach(function (item) {
                if (item.LstGroupContacts && item.LstGroupContacts.length > 0) {
                    item.GroupContactsString = '<ul>';
                    item.LstGroupContacts.forEach(function (itemGrp) {
                        item.GroupContactsString += '<li>' + itemGrp + "</li>";
                    });
                    item.GroupContactsString += '</ul>';
                }

                if (item.TcUsers && item.TcUsers.length > 0) {
                    item.TcUsersString = '<ul>';
                    item.TcUsers.forEach(function (tc) {
                        item.TcUsersString += '<li>' + tc.FullName + "</li>";
                    });
                    item.TcUsersString += '</ul>';

                }

            });
        }

        $scope.getData = function (pageNo) {
            $scope.pageNo = pageNo;
            $scope.LoadDataUser();
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.data.sortKey = sortKey;
            $scope.data.reverse = reverse;
            $scope.LoadDataUser();
        };

        $scope.AddLinkTC = function (user, index, popup) {
            $scope.errorMessagePopup = "";
            $scope.successMessage = "";
            $scope.PopUpLinkTCTitle = "Ajouter une liaison avec un TC";
            $scope.currentUser = user;
            loadTcUserForAutoCompletionPopUp(popup);
        };

        $scope.VerifLinkTC = function () {
            $scope.errorMessagePopup = "";
            if (!$scope.selectedAutoCompleteInput) {
                $scope.errorMessagePopup = "Impossible d'ajouter un TC vide.";
            }
            else if (!$scope.selectedAutoCompleteObject.originalObject) {
                $scope.errorMessagePopup = "Impossible d'ajouter un TC qui n'existe pas dans la base de données.";
            }

            if ($scope.errorMessagePopup.length > 0) {
                return false;
            } else {
                return true;
            }
        };

        $scope.SaveLinkTC = function () {
            $scope.userAction.IdTcUser = $scope.selectedAutoCompleteObject.originalObject.IdUser;
            $scope.DoSelectedAction();
        };

        $scope.VerifAndSaveLinkTCTable = function () {
            $scope.errorMessagePopup = "";
            $scope.successMessage = "";
            if (!$scope.selectedAutoCompleteInput) {
                $scope.errorMessagePopup = "Impossible d'ajouter un TC vide.";
            }
            else if (!$scope.selectedAutoCompleteObject.originalObject) {
                $scope.errorMessagePopup = "Impossible d'ajouter un TC qui n'existe pas dans la base de données.";
            }
            else if ($scope.currentUser.TcUsers && $scope.currentUser.TcUsers.filter(function (elementTc) {
                return elementTc.IdUser === $scope.selectedAutoCompleteObject.originalObject.IdUser;
            }).length > 0) {
                $scope.errorMessagePopup = "Le TC est déjà lié à cet utilisateur.";
            }
            if ($scope.errorMessagePopup.length === 0) {
                userService.SaveLinkTc($scope.currentUser.IdUser, $scope.selectedAutoCompleteObject.originalObject.IdUser, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.errorMessagePopup = data[0].errors[0].Message;
                        }
                    } else {
                        $scope.selectedAutoCompleteObject = {};
                        $scope.selectedAutoCompleteInput = '';
                        $scope.LoadDataUser(true);
                        $scope.successMessage = "La liaison avec le TC a bien été ajoutée.";
                        $scope.currentUser.TcUsers = data;
                    }
                });
            }
        };

        $scope.DeleteLinkTc = function (user, idTcUser) {
            $scope.errorMessagePopup = "";
            $scope.successMessage = "";
            userService.DeleteLinkTc(user.IdUser, idTcUser, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessagePopup = data[0].errors[0].Message;
                    }
                } else {
                    $scope.successMessage = "La liaison entre l'agriculteur et le TC a bien été supprimée";
                    $scope.currentUser.TcUsers = data;
                    user.TcUsers = data;
                }
            });
        };

        $scope.ExportUsers = function () {
            var actif = $scope.isActif == "-1" ? "" : $scope.isActif;
            if (!$scope.idCompagnySelected) $scope.idCompagnySelected = "";
            if (!$scope.searchText) $scope.searchText = "";
            if (!$scope.idGroupContactSelected) $scope.idGroupContactSelected = "";

            var downloadPath = configurationFactory.Url['Api'] + 'User/GetUsersXls/' +
                '?idRole=' + $scope.userAction.IdRole +
                '&idLanguage=' + $rootScope.language.IdLanguage +
                '&isActif=' + actif +
                '&idCompagny=' + $scope.idCompagnySelected +
                '&idGroupContact=' + $scope.idGroupContactSelected +
                '&searchText=' + $scope.searchText +
                '&sortKey=' + $scope.data.sortKey +
                '&reverse=' + $scope.data.reverse;
            window.open(downloadPath, '_blank', '');
        };
    }]);
