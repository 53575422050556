AgrimarketControllers.controller('documentsController', [
    '$scope', '$sce', '$rootScope', '$filter', 'userService', 'configurationFactory', 'dateService', 'signatureService', 'currentUserFactory', function ($scope, $sce, $rootScope, $filter, userService, configurationFactory, dateService, signatureService, currentUserFactory) {

        $scope.Init = function () {
            $scope.errorMessage = "";
            $scope.selectedAutoCompleteObject = {};
            $scope.selectedAutoCompleteInput = '';
            $scope.documentsfilter = {};
            $scope.prefixDateUpdDateDocument = 'Le ';
            $scope.currentDocumentUrlPdf = null;
            $scope.keyMenu = 'Menu_User_Documents';

            $scope.selectedConnection = {};
            $scope.signatureYouSign = {};
            $scope.moduleYousignIsActive = $rootScope.ModuleSignatureElectroniqueYouSign.Active;
            $scope.listConnection = [];
            $scope.showConfirmButton = true;
            $scope.user = currentUserFactory.User;
            $scope.security = {
                Code: ""
            };

            $scope.search = {
                BeginDate: null,
                EndDate: null
            };

            userService.GetDocumentsUser($rootScope.crop.IdCrop, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        var errorMessage = data[0].errors[0].Message + "</br>.";
                        $scope.errorMessage = $sce.trustAsHtml(errorMessage);
                    }
                } else {
                    $scope.lstAllDocumentsByCrop = $scope.lstDocuments = $scope.documentsfilter = data.Documents;
                    $scope.lstDocumentsCategories = data.DocumentsCategories;

                    if ($scope.lstDocumentsCategories.length > 0)
                        $scope.idDocumentCategorySelected = $scope.lstDocumentsCategories[0].Id;

                    $scope.Filter();
                }
            });
        };

        $scope.ShowPopUpSignature = function (idDocument, openPopUpSignature) {
            $scope.successMessage = "";
            $scope.errorMessageSignature = "";
            openPopUpSignature();
            sendSecurityCode(idDocument);
        };

        function sendSecurityCode(idDocument) {
            signatureService.SendSmsForDocumentSignature(idDocument, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessageSignature = data[0].errors[0].Message;
                    }
                } else {
                    if (data) {
                        $scope.signatureYouSign = data;
                    }
                }
            });
        }

        $scope.CheckSmsForYouSign = function (cancelSignaturePopUp) {
            $scope.showConfirmButton = false;
            $scope.errorMessageSignature = "";
            signatureService.SignatureWithCodeYouSign($scope.signatureYouSign.Signature.FilesIds[0], $scope.newAuthenticationId ? $scope.newAuthenticationId : $scope.signatureYouSign.Signature.AuthenticationId, $scope.security.Code, "", function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessageSignature = data[0].errors[0].Message;
                        $scope.showConfirmButton = true;
                    }
                }
                else {
                    if (data && (data === "true" || data === "1")) {
                        $scope.errorMessageSignature = "";
                        cancelSignaturePopUp();
                        $scope.Init();
                    }
                    else {
                        $scope.showConfirmButton = true;
                        $scope.successMessage = "";
                        if (!$scope.security.Code) {
                            $scope.errorMessageSignature = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterCodeReceivedBySms.Content;
                        }
                        else
                            $scope.errorMessageSignature = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_CodeEnteredNotCorrespondToCodeSended.Content;
                    }
                }
            });
        };

        $scope.ResendSecurityCodeYouSign = function () {
            $scope.errorMessageSignature = "";
            signatureService.ResendCodeYouSign($scope.signatureYouSign.Signature.MemberId, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessageSignature = data[0].errors[0].Message;
                    }
                }
                else {
                    if (data) {
                        $scope.newAuthenticationId = data.AuthenticationId;
                        $scope.errorMessageSignature = "";
                        $scope.successMessage = $rootScope.editableObjectsFactory.EditableObjects.Success_OfferSubscribe_CodeResend.Content;
                    }
                }
            });
        };

        $scope.GetPdfDocument = function (item) {
            if (item) {
                $scope.idDocumentDisplay = item.IdDocument;
                $scope.currentDocumentUrlPdf = $sce.trustAsResourceUrl($filter('pdfLinkDocument')({k: item.IdDocument, h: item.Hash})) ;

            }
        };

        $scope.$watch("selectedAutoCompleteInput",
            function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    $scope.Filter();
                }
            });

        $scope.$watch(
            "search.BeginDate",
            function (newValue) {
                if (newValue === "" || newValue === undefined || newValue === null) {
                    $scope.dateFilterBegin = "";
                }
                else {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format dd/mm/yyyy.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.dateFilterBegin = converDate.format('DD/MM/YYYY');
                }
            });

        $scope.$watch(
            "search.EndDate",
            function (newValue) {
                if (newValue === "" || newValue === undefined || newValue === null) {
                    $scope.dateFilterEnd = "";
                }
                else {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format dd/mm/yyyy.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.dateFilterEnd = converDate.format('DD/MM/YYYY');
                }
            });

        $scope.$watch(
            "dateFilterBegin",
            function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    $scope.Filter();
                }
            });

        $scope.$watch(
            "dateFilterEnd",
            function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    $scope.Filter();
                }
            });


        $scope.Filter = function () {
            $scope.errorMessage = "";
            $scope.currentDocumentUrlPdf = null;
            var filterGeneral = angular.copy($scope.lstAllDocumentsByCrop);

            if ($scope.idDocumentCategorySelected) {
                filterGeneral = $filter('filter')(filterGeneral, { IdDocumentCategory: $scope.idDocumentCategorySelected }, true);
            }

            if ($scope.selectedAutoCompleteInput) {
                filterGeneral = filterGeneral.filter(function (element) {
                    if (checkElement(element.NumDocument, $scope.selectedAutoCompleteInput)) {
                        return element;
                    }
                    return null;
                });
            }

            if ($scope.dateFilterBegin) {
                filterGeneral = filterGeneral.filter(function (element) {
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(element.DateDocument.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    var dateElementBegin = converDate.format('DD/MM/YYYY');

                    if (!dateService.compareDate(dateElementBegin, $scope.dateFilterBegin))
                        return element;
                    else
                        return null;
                });
            }

            if ($scope.dateFilterEnd) {
                filterGeneral = filterGeneral.filter(function (element) {

                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(element.DateDocument.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    var dateElementEnd = converDate.format('DD/MM/YYYY');

                    if (!dateService.compareDate($scope.dateFilterEnd, dateElementEnd))
                        return element;
                    else
                        return null;
                });
            }

            $scope.documentsfilter = $scope.lstDocuments = filterGeneral;

            if ($scope.documentsfilter && $scope.documentsfilter.length > 0)
                $scope.idDocumentCategorySelected = $scope.documentsfilter[0].IdDocumentCategory;
        };

        function checkElement(attr, string) {
            if (attr.toLowerCase().indexOf(string.toLowerCase()) !== -1)
                return true;
            else return false;
        }

        $scope.AsChangedCrop = function () {
            $scope.Init();
        };

        $scope.Init();
    }]);