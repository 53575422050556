AgrimarketControllers.controller('savePageController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$http', '$location', 'enumFactory', 'contentService', 'configurationFactory',
    function ($scope, $rootScope, $routeParams, $sce, $filter, $http, $location, enumFactory, contentService, configurationFactory) {

        $scope.indexPage = $routeParams.indexPage;
        $scope.indexMenuOrigine = $routeParams.indexMenu;
        $scope.roles = enumFactory.Roles;
        $scope.profils = enumFactory.Profiles;
        $rootScope.resetMenu = false;
        $scope.profileOptionPage = [];

        var GetProfiles = function () {
            contentService.GetProfiles(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data !== undefined && data !== null) {
                        $scope.profileOptionPage = data.ProfilesList;
                        $scope.profileOptionFilter = angular.copy($scope.profileOptionPage);
                    } else {
                        $scope.errorMessage = "Aucun profil n'a été trouvé en base de données";
                    }
                }
            });
        };

        $rootScope.onRefresh = function () {
            Init();
        };

        var InitMessage = function () {
            $scope.pageErrorMessage = "";
        };

        var Init = function () {
            InitMessage();
            if ($scope.indexPage === undefined || $scope.indexPage === null || $scope.indexPage === "-1") { //nouvelle page
                $scope.blocTitle = "Création d'une page de contenu";
                $scope.page = { Publied: "true" };

            } else { //modifier une page
                $scope.blocTitle = "Modification d'une page de contenu";
                $scope.page = angular.copy($rootScope.contents[$scope.indexMenuOrigine].Pages[$scope.indexPage]);
                $scope.page.Publied = $scope.page.Publied ? "true" : "false";
                $scope.page.IndexMenu = $scope.indexMenuOrigine;

                if (($scope.page.Roles.length > 1 || $scope.page.GroupContact.length > 1) || ($scope.page.Roles.length === 0 && $scope.page.GroupContact.length === 0)) {
                    $scope.page.IdProfile = "Gnrl_" + 0;
                } else {
                    //Profil user
                    if ($scope.page.Roles.length == 1)
                        $scope.page.IdProfile = "User_" + $scope.page.Roles[0];
                    else {
                        //group User
                        if ($scope.page.GroupContact.length == 1)
                            $scope.page.IdProfile = "GroupUser_" + $scope.page.GroupContact[0];
                    }
                }
            }
            GetProfiles();
        };

        $scope.UploadImage = function (data) {
            CKEDITOR.instances.Content.insertHtml("<img src='" + $filter('uploadLinkInline')(data.PrivateLabel) + "'>");
        };

        $scope.UploadAttachments = function (data) {         
            CKEDITOR.instances.Content.insertHtml("{{Attachment|" + data.IdAttachment + "|" + $scope.fileName + "}}");
        };


        $scope.SavePage = function () {
            InitMessage();
            if ($scope.page.IdProfile === undefined || $scope.page.IdProfile === "" || $scope.page.IdProfile === null)
                $scope.pageErrorMessage += "Veuillez renseigner le profil concerné.<br/>";
            else {
                $scope.page.Roles = [];
                $scope.page.GroupContact = [];

                if ($scope.page.IdProfile === "Gnrl_" + 0) {
                    $scope.page.ProfilLabel = "tous";
                    $scope.page.Roles = [$scope.page.IdProfile];
                    $scope.page.GroupContact = [$scope.page.IdProfile];
                } else {
                    if ($scope.page.IdProfile === "User_" + $scope.roles.User) {
                        $scope.page.ProfilLabel = $filter('filter')($scope.profileOptionPage, { Value: $scope.page.IdProfile }, true)[0].Name;
                        $scope.page.Roles = [$scope.roles.User];
                    } else {
                        if ($scope.page.IdProfile === "User_" + $scope.roles.TC) {
                            $scope.page.ProfilLabel = $filter('filter')($scope.profileOptionPage, { Value: $scope.page.IdProfile }, true)[0].Name;
                            $scope.page.Roles = [$scope.roles.TC];
                        } else {
                            $scope.page.ProfilLabel = $filter('filter')($scope.profileOptionPage, { Value: $scope.page.IdProfile }, true)[0].Name;
                            $scope.page.GroupContact = [parseInt($scope.page.IdProfile.split("_")[1])];
                        }
                    }
                }
            }

            if ($scope.page.Label === undefined || $scope.page.Label === "" || $scope.label === null)
                $scope.pageErrorMessage += "Veuillez renseigner un titre.<br/>";

            if ($scope.page.IndexMenu === undefined || $scope.page.IndexMenu === "" || $scope.page.IndexMenu === null)
                $scope.pageErrorMessage += "Veuillez renseigner un menu.<br/>";

            if ($scope.pageErrorMessage !== "") {
                $scope.pageErrorMessage = $sce.trustAsHtml($scope.pageErrorMessage);
                return false;
            }
            $scope.page.Publied = $scope.page.Publied == "true";

            if ($scope.indexMenuOrigine !== undefined && $scope.indexMenuOrigine !== null && $scope.indexMenuOrigine !== "-1") { // mode edition de page
                if ($scope.indexMenuOrigine === $scope.page.IndexMenu) { // si on a pas changé le menu de la page
                    $rootScope.contents[$scope.indexMenuOrigine].Pages[$scope.indexPage] = $scope.page;
                    $rootScope.showDetails[$scope.indexMenuOrigine] = true;
                }
                else { // si le menu de la page a changé
                    $rootScope.contents[$scope.indexMenuOrigine].Pages.splice($scope.indexPage, 1);
                    $scope.page.Position = $rootScope.contents[$scope.page.IndexMenu].Pages.length + 1;
                    $rootScope.contents[$scope.page.IndexMenu].Pages.push($scope.page);
                    $rootScope.showDetails[$scope.page.IndexMenu] = true;
                }
            }
            else { // mode création de page
                $scope.page.Position = $rootScope.contents[$scope.page.IndexMenu].Pages.length + 1;
                $rootScope.contents[$scope.page.IndexMenu].Pages.push($scope.page);
                $rootScope.showDetails[$scope.page.IndexMenu] = true;
            }
            $scope.Cancel();
            return true;
        };

        $scope.$watch('page.IndexMenu + profileOptionPage', function () {
            if ($scope.profileOptionFilter && $scope.page.IndexMenu) {

                var filterGeneral = angular.copy($scope.profileOptionFilter);

                if (($rootScope.contents[$scope.page.IndexMenu].Roles.length > 0 && $rootScope.contents[$scope.page.IndexMenu].GroupContact.length > 0) || ($rootScope.contents[$scope.page.IndexMenu].Roles.length === 0 && $rootScope.contents[$scope.page.IndexMenu].GroupContact.length === 0)) {
                    $scope.profileOptionPage = angular.copy(filterGeneral.filter(function (element) {
                        return element.Value;
                    }));
                    if ($scope.indexPage === undefined || $scope.indexPage === null || $scope.indexPage === "-1" || $filter('filter')($scope.profileOptionPage, {
                        Value: $scope.page.IdProfile
                    }, true)[0] === undefined)
                        $scope.page.IdProfile = "Gnrl_" + 0;
                }
                else {
                    if ($rootScope.contents[$scope.page.IndexMenu].Roles.length > 0) {                      

                        if ($rootScope.contents[$scope.page.IndexMenu].Roles[0] === $scope.roles.User) {
                            $scope.profileOptionPage = angular.copy(filterGeneral.filter(function (element) { return element.Value !== "User_" + $scope.roles.TC && element.Value !== "Gnrl_" + 0; }));
                        }
                        else {
                            $scope.profileOptionPage = angular.copy(filterGeneral.filter(function (element) {
                                return element.Value === "User_" + $scope.roles.TC;
                            }));
                        }
                        if ($scope.indexPage === undefined || $scope.indexPage === null || $scope.indexPage === "-1" || $filter('filter')($scope.profileOptionPage, { Value: $scope.page.IdProfile }, true)[0] === undefined)
                            $scope.page.IdProfile = "User_" + $rootScope.contents[$scope.page.IndexMenu].Roles[0];
                    } else {
                        $scope.page.IdProfile = "GroupUser_" + $rootScope.contents[$scope.page.IndexMenu].GroupContact[0];
                        $scope.profileOptionPage = angular.copy(filterGeneral.filter(function (element) { return element.Value === "GroupUser_" + $rootScope.contents[$scope.page.IndexMenu].GroupContact[0]; }));
                    }
                }
            }
        });

        $scope.Cancel = function () {
            $location.path('/admin/contents/content');
        };

        Init();
    }]);
