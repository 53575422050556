AgrimarketControllers.controller('outstandingsController', [
	'$scope', '$rootScope', '$location', 'colonnes', 'outstandingsService', 'parametersFactory', 'configurationFactory', 'currentUserFactory',
	function ($scope, $rootScope, $location, colonnes, outstandingsService, parametersFactory, configurationFactory, currentUserFactory) {

		if (!$rootScope.ModuleMyAccount.Active)
			$location.path('/');

		$scope.isDisplayPDFColumn = (parametersFactory.Parameters.bDisplayPDFOutstanding.Value === '1');

		var init = function () {
			$scope.bLightOutstandings = parametersFactory.Parameters.bLightOutstandings.Value === "1";
			$scope.reverseCreditAndDebitOutstandings = parametersFactory.Parameters.ReverseCreditAndDebitOutstandings.Value === "1";
			$scope.data = { reverse: false, sortKey: "Date" };
			$scope.pageNo = 1;
			$scope.max = 10;
			$scope.errorMessage = "";
			$scope.filterContent = {
				BeginDate: "",
				EndDate: "",
				SearchText: "",
				IdTypeOutstandingSelected: "",
				BeginDateFilter: "",
				EndDateFilter: ""
			};
			$scope.typeOutstandings = {};
			$scope.outstandings = [];
			$scope.All = $rootScope.editableObjectsFactory.EditableObjects.AllItems_F.Content;

			if ($scope.bLightOutstandings) {
				colonnes.data.DataVisible = colonnes.data.DataVisible.replace(",OperationLabel", "");
				colonnes.data.ColumnName = colonnes.data.ColumnName.replace(",Opération", "");
			}

			$scope.tableOutstandingsDataVisible = colonnes.data.DataVisible.split(',');
			$scope.tableOutstandingsLabels = colonnes.data.ColumnName.split(',');

			$scope.GetFilters();
		};

        $scope.ExportOutstandings = function () {
            var downloadPath = outstandingsService.GetOutstandingsXls($scope.filterContent.idCompagnySelected, currentUserFactory.User.IdClientSelectedFromTc ? currentUserFactory.User.IdClientSelectedFromTc : currentUserFactory.User.IdUser, $scope.filterContent.BeginDateFilter, $scope.filterContent.EndDateFilter, $scope.filterContent.SearchText, $rootScope.language.IdLanguage);
            window.open(downloadPath, '_blank', '');
        };

		$scope.GetFilters = function () {
			outstandingsService.GetFilters(function (data, status) {
				if (status !== null) {
					if (status == 400)
						$scope.errorMessage = data[0].errors[0].Message;
					else
						$scope.errorMessage = data;
				}
				else {
					$scope.compagnies = data;
					$scope.compagnies.forEach(function (item) {
						if (!item.Id)
							item.Id = "";
					});

					if ($scope.compagnies.length > 1)
						$scope.compagnies.unshift({ Id: -1, Label: $scope.All });

					var compagnieIsDefault = $scope.compagnies.find(function (compagnie) {
						return compagnie.IsDefault === true;
					});

					if ($scope.compagnies.length > 0) {
						if (compagnieIsDefault)
							$scope.filterContent.idCompagnySelected = compagnieIsDefault.Id;
						else
							$scope.filterContent.idCompagnySelected = $scope.compagnies[0].Id;
					}

					$scope.GetTypeOutstandings();
				}
			});
		};

		$scope.GetTypeOutstandings = function () {
			outstandingsService.GetTypeOutstandings($scope.filterContent.idCompagnySelected ? $scope.filterContent.idCompagnySelected : "", function (data, status) {
				if (status !== null) {
					if (status === 400)
						$scope.errorMessage = data[0].errors[0].Message;
				}
				else {
					$scope.typeOutstandings = data;

					if ($scope.typeOutstandings.length > 0) {
						$scope.filterContent.IdTypeOutstandingSelected = $scope.typeOutstandings[0].IdTypeOutstanding;
					}
					else {
						$scope.firstTotalIndex = -1;
						$scope.secondTotalIndex = -1;
					}
					$scope.GetOutstandingsPaginateWithFilter();
				}
			});
		};

		$scope.GetOutstandingsPaginateWithFilter = function () {
		    $rootScope.loading = true;
			outstandingsService.GetOutstandingsPaginateWithFilter($scope.bLightOutstandings ? "" : $scope.filterContent.IdTypeOutstandingSelected, $scope.filterContent.idCompagnySelected ? $scope.filterContent.idCompagnySelected : "", $scope.filterContent.SearchText, $scope.filterContent.BeginDateFilter, $scope.filterContent.EndDateFilter, $scope.max, $scope.pageNo, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
				if (status !== null) {
					if (status === 400)
					    $scope.errorMessage = data[0].errors[0].Message;
				}
				else {
					$scope.outstandings = data.ListVm;
					$scope.total = data.Total;
					$scope.debitSold = data.DebitSold;

					var indexDebit = $scope.tableOutstandingsDataVisible.indexOf("Debit");
					var indexCredit = $scope.tableOutstandingsDataVisible.indexOf("Credit");

					if (indexDebit === -1 && indexCredit !== -1) {
						$scope.firstTotalIndex = indexCredit;
						$scope.firstTotalValue = data.TotalCredit;
					}
					else if (indexCredit === -1 && indexDebit !== -1) {
						$scope.firstTotalIndex = indexDebit;
						$scope.firstTotalValue = data.TotalDebit;
					}
					else if (indexCredit !== -1 && indexDebit !== -1) {
						if (indexDebit < indexCredit) {
							$scope.firstTotalIndex = indexDebit;
							$scope.firstTotalValue = data.TotalDebit;
							$scope.secondTotalIndex = indexCredit;
							$scope.secondTotalValue = data.TotalCredit;
						}
						else {
							$scope.firstTotalIndex = indexCredit;
							$scope.firstTotalValue = data.TotalCredit;
							$scope.secondTotalIndex = indexDebit;
							$scope.secondTotalValue = data.TotalDebit;
						}
					}
				}
				$rootScope.loading = false;
			});
		};

		$scope.ChangeTypeOutstanding = function(idType) {
			$scope.filterContent.IdTypeOutstandingSelected = idType;
			$scope.GetOutstandingsPaginateWithFilter();
		};

		$rootScope.onRefresh = function () {
			init();
		};

		$scope.GetData = function (pageNo) {
			$scope.pageNo = pageNo;
			$scope.GetOutstandingsPaginateWithFilter();
		};

		$scope.Sort = function (sortKey, reverse) {
			$scope.data.sortKey = sortKey;
			$scope.data.reverse = reverse;
			$scope.GetOutstandingsPaginateWithFilter();
		};

		$scope.$watch("filterContent.BeginDate", function (newValue) {
			if (newValue === "")
				$scope.filterContent.BeginDateFilter = "";
			else if (newValue) {
				//Permet de gérer l'internalisation de tout les formats des dates possibles.
				//Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
				var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
				var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
				var currentDate = new Date(dt.toString());
				var converDate = moment(currentDate);
				$scope.filterContent.BeginDateFilter = converDate.format('YYYY/MM/DD');
			}
		});

		$scope.$watch("filterContent.EndDate", function (newValue) {
			if (newValue === "")
				$scope.filterContent.EndDateFilter = "";
			else if (newValue) {
				//Permet de gérer l'internalisation de tout les formats des dates possibles.
				//Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
				var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
				var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
				var currentDate = new Date(dt.toString());
				var converDate = moment(currentDate);
				$scope.filterContent.EndDateFilter = converDate.format('YYYY/MM/DD');
			}
		});

		$scope.$watchCollection('[filterContent.SearchText, filterContent.BeginDateFilter, filterContent.EndDateFilter]', function (newValue, oldValue) {
			if ((newValue && oldValue !== newValue) || (!newValue && oldValue)) {
				$scope.GetOutstandingsPaginateWithFilter();
			}
		});

		init();
	}
]);